import { computed, isRef, ref, unref, watch } from 'vue';
import { useAptosV2Client } from '@/composables/useAptosV2Client';
import {
  MaybeRef,
  tryOnScopeDispose,
  tryOnUnmounted,
  useTimeoutPoll,
} from '@vueuse/core';
import { useStore } from '@/store';
import { useCurrencyFormat } from '@/composables/useCurrencyFormat';
import { getFromCache } from '@/utils/cache';
import isEqual from 'lodash/isEqual';
import { useWalletPlugin } from '@/composables/useWalletPlugin';
import { RECALCULATION_BALANCE_TIME } from '@/constants';
import { AptosCoinInfoResource } from '@/types/aptosResources';
import { composeType } from '@/utils/contracts';
import { moduleAddress } from '@/utils/networkData';
import { MoveStructId } from '@aptos-labs/ts-sdk';

export function useAccountBalance(
  address: MaybeRef<string | undefined>,
  token: MaybeRef<string | undefined>,
) {
  let firstFetchFlag = false;
  const isFirstFetching = ref(true);
  const isFetching = ref(true);
  const isExists = ref(false);
  const balance = ref(0);
  const { pause, resume } = useTimeoutPoll(
    watchBalance,
    RECALCULATION_BALANCE_TIME,
    {
      immediate: false,
    },
  );

  const client = useAptosV2Client();

  tryOnUnmounted(() => pause());
  tryOnScopeDispose(() => pause());

  async function watchBalance() {
    isFetching.value = true;
    await fetchBalance(unref(address), unref(token));
    isFetching.value = false;
  }

  async function fetchBalance(
    fnAddress?: string,
    fnToken?: string,
  ): Promise<{ balance: number; isExists: boolean } | undefined> {
    if (!fnAddress || !fnToken) {
      return;
    }

    if (firstFetchFlag) {
      isFirstFetching.value = false;
    }

    firstFetchFlag = true;
    const cacheKey = `${fnAddress}-${fnToken}`;

    if (fnToken.includes('lp')) {
      const resource = await getFromCache(
        cacheKey,
        () => {
          return client.client.getAccountResource<AptosCoinInfoResource>({
            accountAddress: fnAddress,
            resourceType: composeType(moduleAddress('CoinStore'), [
              fnToken,
            ]) as MoveStructId,
          });
        },
        { time: 5000 },
      );

      if (cacheKey === `${unref(address)}-${unref(token)}`) {
        console.log('RESOURSE', resource);
        if (resource) {
          isExists.value = true;
          balance.value = +resource.coin.value;
        } else {
          isExists.value = false;
          balance.value = 0;
        }
      }
    } else {
      const balances = await getFromCache(
        cacheKey,
        () => {
          return client.client.getCurrentFungibleAssetBalances({
            options: {
              where: {
                owner_address: { _eq: fnAddress },
                asset_type: { _eq: fnToken },
              },
            },
          });
        },
        { time: 5000 },
      );

      if (cacheKey === `${unref(address)}-${unref(token)}`) {
        if (balances?.length) {
          isExists.value = true;
          balance.value = +balances[0].amount;
        } else {
          isExists.value = false;
          balance.value = 0;
        }
      }
    }

    return {
      balance: balance.value,
      isExists: isExists.value,
    };
  }

  async function refetch() {
    isFetching.value = true;
    const result = await fetchBalance(unref(address), unref(token));
    isFetching.value = false;

    return result;
  }

  resume();
  if (isRef(address) || isRef(token)) {
    watch(
      () => [unref(address), unref(token)],
      async (a, b) => {
        if (isEqual(a, b)) {
          return;
        }

        // Reset fetching state after arguments is changed
        pause();
        firstFetchFlag = false;
        isFirstFetching.value = true;
        isFetching.value = true;
        await fetchBalance(unref(address), unref(token)).then(() => {
          resume();
          isFetching.value = false;
        });
      },
    );
  } else {
    isFirstFetching.value = true;
    fetchBalance(unref(address), unref(token));
  }

  return {
    isFetching,
    isFirstFetching,
    isExists,
    balance,
    type: unref(token),
    refetch,
  };
}

export function useCurrentAccountBalance(
  token: MaybeRef<string | undefined>,
  options?: { useSuffix?: boolean; _decimals?: number },
) {
  const { account } = useWalletPlugin();
  const address = computed(() => account?.value?.address);
  const balance = useAccountBalance(address as unknown as string, token);
  const currencyFormat = useCurrencyFormat(balance.balance, token, options);

  return {
    ...balance,
    ...currencyFormat,
  };
}

export function useNativeBalance(
  address: MaybeRef<string | undefined>,
  options?: { useSuffix?: boolean },
) {
  const store = useStore();
  const token = computed(() => store.defaultToken.value);
  const balance = useAccountBalance(address, token);
  const currencyFormat = useCurrencyFormat(balance.balance, token, options);

  return {
    ...balance,
    ...currencyFormat,
  };
}

export function useCurrentNativeBalance(options?: { useSuffix?: boolean }) {
  const store = useStore();
  const token = computed(() => store.defaultToken.value);
  const balance = useCurrentAccountBalance(token);
  const currencyFormat = useCurrencyFormat(balance.balance, token, options);

  return {
    ...balance,
    ...currencyFormat,
  };
}
